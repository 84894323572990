<template>
	<div>
		<HeadCard />
		<div class="RechargeBox" v-if="purchaseState">
			<div class="RechargeBoxTop">
				<p class="RechargeBoxTopP">充值购买</p>
				<div class="RechargeDetails">
					<div>
						<a-input v-decorator="['amountOfoney']" style="width: 580px; height: 32px" v-model="amountOfoney" type="number" placeholder="输入充值金额" />
						<div style="margin-top: 10px">
							<!-- <a-button type="primary" v-if="amountOfoney >= this.CONSTNAME.minNumber" class="butMargin" @click="aliPay">支付宝充值</a-button> -->
							<a-button type="primary" v-if="amountOfoney >= this.CONSTNAME.minNumber" class="butMargin" @click="wechatPay">微信充值</a-button>
							<a-button type="primary" disabled v-if="amountOfoney < this.CONSTNAME.minNumber">充值金额最低{{ this.CONSTNAME.minNumber }}元</a-button>
						</div>
					</div>
					<div class="PaymentInformation">
						<span>总计增加余额：￥{{ amountOfoney ? amountOfoney : '0.00' }}元</span>
						<p>总计需支付：￥{{ amountOfoney ? amountOfoney : '0.00' }}元</p>
					</div>
				</div>
			</div>
			<div class="RechargeList">
				<div class="RechargeListTitle">
					<a-menu v-model="current" mode="horizontal">
						<a-menu-item key="SetMeal">
							<a-icon type="SetMeal" />
							购买套餐
						</a-menu-item>
						<a-menu-item key="flow">
							<a-icon type="flow" />
							购买流量
						</a-menu-item>
						<a-menu-item key="PurchaseRecords">
							<a-icon type="PurchaseRecords" />
							购买记录
						</a-menu-item>
						<a-menu-item key="RechargeRecord">
							<a-icon type="RechargeRecord" />
							充值记录
						</a-menu-item>
					</a-menu>
				</div>
				<!-- 购买套餐 -->
				<div v-show="current[0] === 'SetMeal'" class="mainbox">
					<a-collapse v-model="activeKey" accordion>
						<a-collapse-panel key="5" header="月付套餐">
							<div class="package">
								<article v-for="item in monthlys" :key="item.id">
									<p>{{ item.name }} - 月付</p>
									<p class="packagePBox">
										<span class="spanPackage">{{ item.price / 100 }}元</span>
										<span>/月</span>
									</p>
									<div class="packageInfo">
										<p>
											<a-icon type="user" />
											&nbsp; 设备数:不限制 &nbsp;
											<a-tooltip placement="topLeft" title="仅限用户一人合理使用"><a-icon type="question-circle" /></a-tooltip>
										</p>
										<p>
											<a-icon type="clock-circle" />
											&nbsp; 时长: {{ item.duration_day }}天
										</p>
										<p>
											<a-icon type="thunderbolt" />
											&nbsp; 可用流量: {{ item.traffic_gb }}GB/月 &nbsp;
											<a-tooltip placement="topLeft" title="自用户购买日每31天重置"><a-icon type="question-circle" /></a-tooltip>
										</p>
										<p>
											<a-icon type="heart" />
											&nbsp; 解锁流媒体 &nbsp;
											<a-tooltip placement="topLeft" title="Netflix,AbemaTV,DMM等"><a-icon type="question-circle" /></a-tooltip>
										</p>
									</div>
									<div class="placeOrder">
										<a-button @click="qiehuan(item)">
											<a-icon type="shopping-cart" />
											立即下单
										</a-button>
									</div>
								</article>
							</div>
						</a-collapse-panel>
						<a-collapse-panel key="2" header="季付套餐">
							<div class="package">
								<article v-for="item in quarterlys" :key="item.id">
									<p>{{ item.name }} - 季付</p>
									<p class="packagePBox">
										<span class="spanPackage">{{ item.price / 100 }}元</span>
										<span>/季</span>
									</p>
									<div class="packageInfo">
										<p>
											<a-icon type="user" />
											&nbsp; 设备数:不限制 &nbsp;
											<a-tooltip placement="topLeft" title="仅限用户一人合理使用"><a-icon type="question-circle" /></a-tooltip>
										</p>
										<p>
											<a-icon type="clock-circle" />
											&nbsp; 时长: {{ item.duration_day }}天
										</p>
										<p>
											<a-icon type="thunderbolt" />
											&nbsp; 可用流量: {{ item.traffic_gb }}GB/月 &nbsp;
											<a-tooltip placement="topLeft" title="自用户购买日每31天重置"><a-icon type="question-circle" /></a-tooltip>
										</p>
										<p>
											<a-icon type="heart" />
											&nbsp; 解锁流媒体 &nbsp;
											<a-tooltip placement="topLeft" title="Netflix,AbemaTV,DMM等"><a-icon type="question-circle" /></a-tooltip>
										</p>
									</div>
									<div class="placeOrder">
										<a-button @click="qiehuan(item)">
											<a-icon type="shopping-cart" />
											立即下单
										</a-button>
									</div>
								</article>
							</div>
						</a-collapse-panel>
						<a-collapse-panel key="1" header="年付套餐">
							<div class="package">
								<article v-for="item in yearlys" :key="item.id">
									<p>{{ item.name }} - 年付</p>
									<p class="packagePBox">
										<span class="spanPackage">{{ item.price / 100 }}元</span>
										<span>/年</span>
									</p>
									<div class="packageInfo">
										<p>
											<a-icon type="user" />
											&nbsp; 设备数:不限制 &nbsp;
											<a-tooltip placement="topLeft" title="仅限用户一人合理使用"><a-icon type="question-circle" /></a-tooltip>
										</p>
										<p>
											<a-icon type="clock-circle" />
											&nbsp; 时长: {{ item.duration_day }}天
										</p>
										<p>
											<a-icon type="thunderbolt" />
											&nbsp; 可用流量: {{ item.traffic_gb }}GB/月 &nbsp;
											<a-tooltip placement="topLeft" title="自用户购买日每31天重置"><a-icon type="question-circle" /></a-tooltip>
										</p>
										<p>
											<a-icon type="heart" />
											&nbsp; 解锁流媒体 &nbsp;
											<a-tooltip placement="topLeft" title="Netflix,AbemaTV,DMM等"><a-icon type="question-circle" /></a-tooltip>
										</p>
									</div>
									<div class="placeOrder">
										<a-button type="primary" @click="qiehuan(item)" v-if="item.name === 'Pro'">
											<a-icon type="shopping-cart" />
											立即下单
										</a-button>
										<a-button @click="qiehuan(item)" v-else>
											<a-icon type="shopping-cart" />
											立即下单
										</a-button>
									</div>
								</article>
							</div>
						</a-collapse-panel>
						<a-collapse-panel key="4" header="试用套餐">
							<div class="package">
								<article v-for="item in trygets" :key="item.id">
									<p>试用套餐</p>
									<p class="packagePBox">
										<span class="spanPackage">{{ item.price / 100 }}元</span>
										<span>/次</span>
									</p>
									<div class="packageInfo">
										<p>
											<a-icon type="user" />
											&nbsp; 设备数:不限制 &nbsp;
											<a-tooltip placement="topLeft" title="仅限用户一人合理使用"><a-icon type="question-circle" /></a-tooltip>
										</p>
										<p>
											<a-icon type="clock-circle" />
											&nbsp; 时长: {{ item.duration_day }}天
										</p>
										<p>
											<a-icon type="thunderbolt" />
											&nbsp; 可用流量: {{ item.traffic_gb }}GB &nbsp;
										</p>
										<p>
											<a-icon type="heart" />
											&nbsp; 解锁流媒体 &nbsp;
											<a-tooltip placement="topLeft" title="Netflix,AbemaTV,DMM等"><a-icon type="question-circle" /></a-tooltip>
										</p>
									</div>
									<div class="placeOrder">
										<a-button @click="qiehuan(item)">
											<a-icon type="shopping-cart" />
											立即下单
										</a-button>
									</div>
								</article>
							</div>
						</a-collapse-panel>
					</a-collapse>
				</div>
				<!-- 购买流量 -->
				<div v-show="current[0] === 'flow'" class="mainbox">
					<div class="mattersAttention">
						<p>注意</p>
						<ul>
							<li>只能购买10到1000之间10的倍数的流量,单位G</li>
							<li>购买的流量将在 您当前所使用的套餐 (流量重置日) 之时，一同到期</li>
							<li>拖动下方滑块或在输入框输入流量值进行购买（10-1000G)</li>
							<li>当前套餐流量价格:{{ jiage }}元/G</li>
						</ul>
						<div>
							<a-row>
								<a-col :span="12">
									<a-slider v-model="inputValue1" :min="minJiage" :max="maxJiage" :step="interval_gb" />
								</a-col>
								<a-col :span="4">
									<a-input-number v-model="inputValue1" :min="minJiage" :max="maxJiage" style="marginleft: 16px" :step="interval_gb" /> GB
								</a-col>
							</a-row>

							<a-button type="primary" @click="showModal()">立即下单</a-button>
						</div>
					</div>
				</div>
				<a-modal v-model="visible" title="订单确认" ok-text="确认" cancel-text="取消" @ok="hideModal">
					<p>单价：{{ jiage }}元/G</p>
					<p>购买流量：{{ inputValue1 }}GB</p>
					<p>需支付：{{ (jiage * inputValue1).toFixed(2) }}元</p>
				</a-modal>
				<!-- 购买记录 -->
				<div v-show="current[0] === 'PurchaseRecords'" class="mainbox">
					<a-table
						:columns="columns"
						:loading="loading"
						:data-source="data"
						:total="pagination.total"
						@change="handleTableChange"
						:pagination="pagination"
						v-model="pagination.current"
					></a-table>
				</div>
				<!-- 充值记录 -->
				<div v-show="current[0] === 'RechargeRecord'" class="mainbox">
					<a-table
						:columns="columns1"
						:loading="loading"
						@change="handleTableChange"
						:total="pagination.total"
						:data-source="data"
						v-model="pagination.current"
						:pagination="pagination"
					></a-table>
				</div>
			</div>
		</div>
		<div v-else>
			<RechargeOne @changePurchaseState="changePurchaseStates" :dataArticle="dataArticle" />
		</div>
		<Footer />
	</div>
</template>

<script>
//充值插件
import QRCode from 'qrcodejs2'
import HeadCard from '../../components/HeadCard'
import RechargeOne from './rechargeOne.vue'
import moment from 'moment'
import api from '../../api/api'
import Footer from '../../components/Footer'
import { message } from 'ant-design-vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('userInfo')
const key = 'updatable'
const productType = {
	yearly: '年付',
	quarterly: '季付',
	monthly: '月付'
}
const columns = [
	{
		title: '订单ID',
		dataIndex: 'id',
		key: 'id'
	},
	{
		title: '商品名称',
		dataIndex: 'product_name',
		key: 'product_name'
	},
	{
		title: '价格',
		dataIndex: 'balance',
		key: 'balance',
		scopedSlots: { customRender: 'balance' }
	},
	{
		title: '购买时间',
		key: 'timestamp',
		dataIndex: 'timestamp'
	}
]

export default {
	inject: ['reload'],
	components: {
		HeadCard,
		RechargeOne,
		Footer
	},
	data() {
		return {
			jiage: 0, //当前流量价格
			minJiage: 0,
			maxJiage: 0,
			interval_gb: 0,
			columns1: [
				{
					title: '订单ID',
					dataIndex: 'order_id',
					key: 'order_id'
				},
				{
					title: '充值金额',
					dataIndex: 'balance',
					key: 'balance'
				},
				{
					title: '充值时间',
					dataIndex: 'timestamp',
					key: 'timestamp',
					scopedSlots: { customRender: 'balance' }
				}
			],
			dataArticle: {}, //保存单个数据做发送接口
			newdata: [],

			monthlys: [],
			quarterlys: [],
			yearlys: [],
			trygets: [],
			loading: false,
			visible: false,
			current1: 1,
			activeKey: ['1'],
			purchaseState: true,
			//折叠
			text: '1234567*9',
			amountOfoney: '',
			current: ['SetMeal'],
			//表哥
			data: [],
			columns,
			//滑块
			inputValue1: 10,
			//分页设置
			pagination: {
				pageSize: 10, // 默认每页显示数量
				showTotal: total => `共 ${total} 条`, // 显示总数
				total: 10, //总条数
				current: 1 //按钮页码
			},
			modalPayParams: ''
		}
	},
	async mounted() {
		const data = await api.product()
		this.getnewdata(data)
		this.newdata = data
		const datana = await api.getproductBuyTraffic()
		// console.log('data', datana)
		this.minJiage = datana.min_gb
		this.maxJiage = datana.max_gb
		this.jiage = datana.price
		this.interval_gb = datana.interval_gb
	},

	computed: {
		...mapState(['userInfo'])
	},
	watch: {
		current: async function () {
			this.loading = true
			this.pagination.current = 1
			console.log(this.pagination.current, 'watch')
			this.data = []
			if (this.current[0] === 'PurchaseRecords') {
				const data = await api.purchaseHistory(this.pagination.current)
				if (data.count>=0) {
					this.loading = false
				}
				this.pagination.total = data.count
				this.data = data.list.filter(item => {
					item.product_name = item.product_name ? `${item.product_name}--${productType[item.product_type]}` : '流量'
					item.balance = item.balance / 100
					item.timestamp = moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss')
					return item
				})
				console.log('data购买记录', data)
			}
			if (this.current[0] === 'RechargeRecord') {
				const data = await api.paymentHistory(this.pagination.current)
				if (data.count>=0) {
					this.loading = false
				}
				this.pagination.total = data.count
				this.data = data.list.filter(item => {
					item.balance = item.balance / 100
					item.key = item.id
					item.timestamp = moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss')
					return item
				})
			}
		}
	},
	methods: {
		async handleTableChange(pagination) {
			this.loading = true
			console.log(pagination)
			this.pagination = pagination
			this.data = []
			if (this.current[0] === 'PurchaseRecords') {
				const data = await api.purchaseHistory(pagination.current)
				if (data.count) {
					this.loading = false
				}
				this.data = data.list.filter(item => {
					item.product_name = item.product_name ? `${item.product_name}--${productType[item.product_type]}` : '流量'
					item.balance = item.balance / 100
					item.timestamp = moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss')
					return item
				})
			}
			if (this.current[0] === 'RechargeRecord') {
				const data = await api.paymentHistory(pagination.current)
				if (data.count) {
					this.loading = false
				}
				this.data = data.list.filter(item => {
					item.balance = item.balance / 100
					item.key = item.id
					item.timestamp = moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss')
					return item
				})
			}
		},
		//
		...mapActions(['getUserInfo']),
		//
		getnewdata(e) {
			this.monthlys = e.filter(item => {
				return item.type == 'monthly'
			})
			this.quarterlys = e.filter(item => {
				return item.type == 'quarterly'
			})
			let dds = e.filter(item => {
				return item.type == 'yearly'
			})
			const xs = dds.pop()
			dds.unshift(xs)
			this.yearlys = dds
			this.trygets = e.filter(item => {
				return item.type == 'try'
			})
		},
		showConfirm(e) {
			const _this = this
			const trade_id = this.codeNuber
			this.$confirm({
				title: `请使用${e}扫码支付`,
				content: h => (
					<div class="buy_box dis_f_c_c">
						<div id="qrcode" ref="qrcode" class="img1 dis_f_c_c" style="display: flex !important;margin:20px 0px 20px 40px!important;"></div>
						<p></p>
						<p>{h ? '充值完成后,请点击 充值完成' : null}</p>
					</div>
				),
				okText: '完成充值',
				okType: 'primary',
				cancelText: '取消',
				onOk() {
					api.paymentStatusyy(`trade_id=${trade_id}`).then(res => {
						if (res.id) {
							message.success('充值成功')
							_this.colsBox()
							_this.reload()
						} else if (res.msg === 'not_exists') {
							message.error(`不存在`)
						} else {
							message.error(`系统未收到支付回调,请重试,如您已付款成功，请在一分钟后尝试.`)
						}
					})
					return Promise.reject
				},

				onCancel() {
					message.error(`已取消支付`)
				},
				class: 'test'
			})
		},
		colsBox() {
			this.$destroyAll()
		},
		//
		//获取状态
		getstate() {},
		//支付宝充值
		async aliPay() {
			this.$message.loading({ content: '正在链接支付网关，请稍等', key })
			const amountOfoney = this.amountOfoney
			const data = await api.paymentStatus({ amount: amountOfoney, type: 'alipay' })
			if (data) {
				this.codeNuber = data.trade_id
				this.showConfirm('支付宝')
				this.$nextTick(() => {
					this.qrcode = new QRCode('qrcode', {
						width: 180, //二维码宽度
						height: 180, //二维码高度
						text: data.qrcode, //调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr=uDKsQ4E00"
						colorDark: '#000', //颜色配置
						colorLight: '#fff'
					})
				})
				this.$message.success({ content: '链接成功', key, duration: 2 })
			}
		},
		//微信充值
		async wechatPay() {
			this.$message.loading({ content: '正在链接支付网关，请稍等', key })
			const amountOfoney = this.amountOfoney
			const data = await api.paymentStatus({ amount: amountOfoney, type: 'weixin' })
			if (data) {
				this.codeNuber = data.trade_id
				this.showConfirm('微信')
				this.$nextTick(() => {
					this.qrcode = new QRCode('qrcode', {
						width: 180, //二维码宽度
						height: 180, //二维码高度
						text: data.qrcode, //调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr=uDKsQ4E00"
						colorDark: '#000', //颜色配置
						colorLight: '#fff'
					})
				})
			}
		},
		callback(key) {
			console.log(key)
		},

		showModal() {
			this.visible = true
		},

		hideModal() {
			this.palyOrder()
			this.visible = false
		},
		changePurchaseStates() {
			this.purchaseState = true
		},
		async palyOrder() {
			if (this.inputValue1 % 10 === 0) {
				const data = await api.productBuyTraffic({ traffic: this.inputValue1 })
				if (data.msg === 'insufficient') {
					message.error('金额不足，请充值')
				} else {
					message.success(`以为你的账户增加了${this.inputValue1}GB流量，若没有生效，请刷新网页`)
					this.getUserInfo()
				}
				//发送接口inputValue1
			} else {
				message.error('请输入整数(10的倍数)')
			}
		},
		//qiehuan
		async qiehuan(e) {
			this.dataArticle = await { ...e }
			// console.log('dataArticle', this.dataArticle)
			this.purchaseState = false
		}
	}
}
</script>

<style scoped lang="scss">
.RechargeBox {
	width: 65%;
	min-width: 1080px;
	margin: auto;
	min-height: 50px;
	// border: 1px red solid;
	.RechargeBoxTop {
		margin-top: 20px;
		width: 100%;
		min-height: 50px;
		box-sizing: border-box;
		background-color: white;
		// border: 1px red solid;
		.RechargeBoxTopP {
			width: 100%;
			height: 56px;
			margin: auto;
			padding: 16px;
			box-sizing: border-box;
			color: rgba(0, 0, 0, 0.85);
			border-bottom: 1px solid #e8e8e8;
		}
		.RechargeDetails {
			margin-top: 10px;

			padding: 15px;
			width: 100%;
			min-height: 40px;
			display: flex;
			justify-content: space-between;
			.butMargin {
				margin-left: 15px;
			}
		}
		.PaymentInformation {
			text-align: left;
			margin-top: 40px;
			margin-bottom: -10px;
			span {
				color: rgba(0, 0, 0, 0.45);
				font-size: 14px;
				font-variant: tabular-nums;
				line-height: 1.5;
			}
			p {
				color: rgba(0, 0, 0, 0.85);
				font-size: 20px;
			}
		}
	}
	.RechargeList {
		width: 100%;
		min-height: 80px;
		margin: auto;
		padding-bottom: 20px;
		box-sizing: border-box;
		background-color: white;
		margin-top: 20px;
		.mainbox {
			width: 100%;
			padding: 0px 10px;
			.mattersAttention {
				width: 100%;
				min-height: 50px;
				box-sizing: border-box;
				padding: 14px;
				// background-color: red;
				li {
					margin-top: 10pxddddd;
				}
			}
			.package {
				padding: 10px;
				display: flex;
				justify-content: space-evenly;
				article {
					width: 300px;
					height: 400px;
					margin-left: 20px;
					border: 1px solid #e8e8e8;
					p {
						padding: 15px;
						box-sizing: border-box;
						font-size: 20px;
						font-weight: 700;
						border-bottom: 1px solid #e8e8e8;
						margin-bottom: 0;
					}
					.packagePBox {
						text-align: center;
						span {
							font-size: 14px;

							color: rgba(37, 55, 79, 0.55);
						}
						.spanPackage {
							font-weight: 700;
						}
					}
					.packageInfo {
						width: 100%;
						padding: 12px;
						box-sizing: border-box;
						border-bottom: 1px solid #e8e8e8;
						p {
							font-size: 14px;
							font-weight: normal;
							border: none;
							padding: 10px;
						}
					}
					.placeOrder {
						padding: 25px;
					}
				}
			}
		}
		.RechargeListTitle {
			box-sizing: border-box;
			padding: 15px;
			margin-top: 15px;
			width: 100%;
		}
	}
}
.AccountRecharge {
}
//滑块
.code-box-demo .ant-slider {
	margin-bottom: 16px;
}
#qrcode {
	display: flex !important;
	box-sizing: border-box !important;
	padding: 10px !important;
	justify-content: center !important;
	margin-bottom: 20px !important;
}
#modBoxP {
	margin-top: 20px;
}
</style>
