<template>
	<div>
		<div class="RechargeBox">
			<a-steps :current="current">
				<a-step v-for="item in steps" :key="item.title" :title="item.title">
					<a-icon slot="icon" :type="item.icon" />
				</a-step>
			</a-steps>
			<div class="steps-content">
				<!-- //第一步 -->
				<div class="contentBox" v-show="current === 0">
					<p>
						请确认以下订单信息:
						<a-button @click="gotoPrev" value="large">重新选择</a-button>
					</p>

					<div class="contentInfo">
						<div class="package">
							<article v-for="item in ArticleInfo" :key="item.id">
								<p>{{ item.name }} - {{ YSOBJ[item.type] }}{{ item.name === 'Try' ? null : '付' }}</p>
								<p class="packagePBox">
									<span class="spanPackage" style="color: rgba(0, 0, 0, 0.65)">{{ item.price / 100 }}</span>
									<span style="color: rgba(37, 55, 79, 0.55)">/ CNY</span>
								</p>
								<div class="packageInfo">
									<p>
										<a-icon type="user" />
										&nbsp; 设备数:不限制 &nbsp;
										<a-tooltip placement="topLeft" title="仅限用户一人合理使用"><a-icon type="question-circle" /></a-tooltip>
									</p>
									<p>
										<a-icon type="clock-circle" />
										&nbsp; 时长: {{ item.duration_day }}天
									</p>
									<p>
										<a-icon type="thunderbolt" />
										&nbsp; 可用流量: {{ item.traffic_gb }}GB/{{ YSOBJ[item.type] }} &nbsp;
										<a-tooltip placement="topLeft" title="自用户购买日每30天重置"><a-icon type="question-circle" /></a-tooltip>
									</p>
									<p>
										<a-icon type="heart" />
										&nbsp; 解锁流媒体 &nbsp;
										<a-tooltip placement="topLeft" title="Netflix,AbemaTV,DMM等"><a-icon type="question-circle" /></a-tooltip>
									</p>
								</div>
							</article>
						</div>
					</div>
					<!-- //说明 -->
					<a-modal title="Title" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
						<p>{{ ModalText }}</p>
					</a-modal>
					<div class="infoExplain">
						<h2>说明</h2>
						<p style="color: red">若您已有套餐,此套餐不会立即启用,而会进入冻结状态,待当前套餐结束后会自动启用</p>
						<p>您可以在购买记录中查看冻结套餐的预计启用时间,也可以强制启用套餐,注意强制启用套餐会重置你的时长和流量</p>
						<p>每个套餐启用后,将会清空之前套餐未使用或超额的流量</p>
					</div>
				</div>
				<!-- //第二部 -->
				<!-- <div class="contentBox" v-show="current === 1">
					<div class="contenTwo">
						<div class="contenTwoLet">
							<p>选择优惠卷</p>
							<a-select default-value="没有优惠卷" style="width: 300px" @change="handleChange">
								<a-select-option value="lucy">
									Lucy
								</a-select-option>

								<a-select-option value="Yiminghe">
									yiminghe
								</a-select-option>
							</a-select>
							<p>选择支付方式</p>
							<div>
								<a-radio-group name="radioGroup" :default-value="1" @change="onChangeRadiockResult">
									<a-radio :value="1">
										<span>
											<a-icon type="pay-circle" />
											&nbsp; 余额
										</span>
									</a-radio>
									<a-radio :value="2">
										<span>
											<a-icon type="alipay" />
											&nbsp;支付宝
										</span>
									</a-radio>
									<a-radio :value="3">
										<span>
											<a-icon type="wechat" />
											&nbsp;微信
										</span>
									</a-radio>
									<a-radio :value="4">
										<span>
											<a-icon type="strikethrough" />
											&nbsp;数字货币
										</span>
									</a-radio>
								</a-radio-group>
							</div>
						</div>
						<div class="contenTworight">
							<p>
								商品名称:
								<span style="color: rgba(0,0,0,.65);">Basic - 月付</span>
							</p>
							<p>
								商品价格:
								<span style="color: rgba(0,0,0,.65);">8.35 CNY</span>
							</p>
							<p>
								优惠券抵扣:
								<span style="color: rgb(255, 120, 0);">0.00 CNY</span>
							</p>
							<p>
								实际需支付:
								<span style="color: rgb(255, 120, 0);font-size: 32px;">
									8.35 CNY
								</span>
							</p>
						</div>
					</div>
				</div> -->
				<!-- //二维码展示 -->
				<!-- <div class="contentBox" v-show="current === 0">
						<p>
						请确认以下订单信息:
						<a-button @click="gotoPrev" value="large">重新选择</a-button>
					</p>
					<div class="QRcode">
						<a-spin>
							<a-icon slot="indicator" type="loading" style="font-size: 54px" spin />
						</a-spin>
						<p style="text-align: center; margin-top:14px;font-size:24px">等待支付</p>
						<div class="QRinfo">
							<p>订单ID:{{ArticleInfo.id}}</p>
							<p>订单商品：Basic - {{ArticleInfo.type}}</p>
							<p>支付方式：支付宝</p>
							<p>支付金额:{{ArticleInfo.price/100}}</p>
						</div>
					</div>
					
				</div> -->
			</div>
			<div class="steps-action">
				<a-button v-if="current < steps.length - 1" type="primary" @click="next">下一步</a-button>
				<a-button v-if="current == steps.length - 1" type="primary" @click="getQR()">提交</a-button>
				<a-button v-if="current > 0" style="margin-left: 8px" @click="prev">上一步</a-button>
			</div>
		</div>
	</div>
</template>

<script>
import { message } from 'ant-design-vue'
import api from '../../api/api'
import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapState } = createNamespacedHelpers('userInfo')
export default {
	data() {
		return {
			//模态框

			ModalText: 'Content of the modal',
			visible: false,
			confirmLoading: false,
			ArticleInfo: [], //拿到单个点击数据
			current: 0,
			// 对象映射
			YSOBJ: {
				yearly: '年',
				try: '试用',
				quarterly: '季',
				monthly: '月'
			},
			steps: [
				{
					title: '支付',
					content: 'Last-content',
					icon: 'smile'
				}
			]
		}
	},	
	computed: {
		...mapState(['userInfo'])
	},
	mounted() {
		this.ArticleInfo.push(this.$attrs.dataArticle)
		// console.log('this.ArticleInfo', this.ArticleInfo)
	},

	methods: {
		...mapActions(['getUserInfo']),
		//motaikuang
		async getQR() {
			const { id } = this.ArticleInfo[0]
			const data = await api.productBuy({ id: id })
			var datastr = JSON.stringify(data, null, 2)
			console.log(`buy response [${datastr}]`)

			if (data.msg === 'insufficient') {
				message.error('余额不足，请充值')
			} else if (!data.msg) {
				message.success('充值成功')
				this.getUserInfo()
				setTimeout(() => {
					this.$router.push('/dashboard')
				}, 1000)
			}
		},
		showModal() {
			this.visible = true
		},
		handleOk(e) {
			console.log('e', e)
			this.ModalText = 'The modal will be closed after two seconds'
			this.confirmLoading = true
			setTimeout(() => {
				this.visible = false
				this.confirmLoading = false
			}, 2000)
		},
		handleCancel(e) {
			console.log('e', e)
			console.log('Clicked cancel button')
			this.visible = false
		},
		//xiala
		handleChange(value) {
			console.log(`selected ${value}`)
		},
		next() {
			this.current++
		},
		prev() {
			this.current--
		},
		//重新选择订单信息
		gotoPrev() {
			console.log('123')
			this.$emit('changePurchaseState')
		}
	}
}
</script>

<style scoped lang="scss">
.RechargeBox {
	width: 1240px;
	margin: auto;
	min-height: 500px;
	background-color: white;
	box-sizing: border-box;
	padding: 18px;
	// border: 1px red solid;
	.steps-content {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 10px;
		.contentBox {
			width: 100%;
			min-height: 30px;
			// background-color: red;
			//tureu
			.QRcode {
				width: 300px;
				margin: auto;
				text-align: center;
				min-height: 200px;
				// background-color: red;
				p {
					font-weight: normal;
					color: rgba(0, 0, 0, 0.65);
					font-size: 14px;
					font-variant: tabular-nums;
					line-height: 1.5;
					list-style: none;
				}
			}
			// two
			.contenTwo {
				width: 100%;
				display: flex;
				justify-content: space-between;
				.contenTwoLet {
					p {
						color: rgba(0, 0, 0, 0.65);
						font-size: 14px;
						font-variant: tabular-nums;
						line-height: 1.5;
						list-style: none;
						margin: 20px 0px;
					}
				}
				.contenTworight {
					box-sizing: border-box;
					padding: 20px 0 0 0;
					width: 400px;

					p {
						text-align: left;
						color: rgba(0, 0, 0, 0.65);
						font-size: 14px;
						margin-bottom: 5px;
						font-variant: tabular-nums;
						line-height: 1.5;
						list-style: none;
						span {
							font-weight: normal;
						}
					}
				}
			}
			p {
				text-align: left;
				font-weight: bold;
				font-size: 18px;
				button {
					border: 1px solid #1890ff;
					color: #1890ff;
				}
			}
			.package {
				padding: 10px;
				display: flex;
				justify-content: space-evenly;
				article {
					width: 300px;
					// height: 400px;
					margin-left: 20px;
					border: 1px solid #e8e8e8;
					p {
						padding: 15px;
						box-sizing: border-box;
						font-size: 20px;
						font-weight: 700;
						border-bottom: 1px solid #e8e8e8;
						margin-bottom: 0;
					}
					.packagePBox {
						text-align: center;
						span {
							font-size: 14px;

							color: rgba(37, 55, 79, 0.55);
						}
						.spanPackage {
							font-weight: 700;
						}
					}
					.packageInfo {
						width: 100%;
						padding: 12px;
						box-sizing: border-box;
						border-bottom: 1px solid #e8e8e8;
						p {
							font-size: 14px;
							font-weight: normal;
							border: none;
							padding: 10px;
						}
					}
					.placeOrder {
						padding: 25px;
					}
				}
			}
		}
	}
	.infoExplain {
		margin-top: 15px;
		width: 100%;
		min-height: 50px;
		box-sizing: border-box;
		border-top: 1px solid #e8e8e8;
		padding: 20px;
		h2 {
			text-align: left;
			color: rgba(0, 0, 0, 0.45);
		}
		p {
			margin-top: 0;
			margin-bottom: 12px;
			line-height: 22px;
			font-weight: bold;
			color: rgba(0, 0, 0, 0.45);
			font-size: 14px !important;
		}
	}

	.steps-action {
		margin-top: 24px;
		display: flex;
		justify-content: center;
	}
}
</style>
